import React from "react"
import Category from "../../templates/category"
import { withTranslation } from 'react-i18next';
import { graphql } from "gatsby"

export default withTranslation()(({ pageContext, data, t }) => {
  const { edges } = data.allMarkdownRemark
  return (
    <Category title={'Tarjouksen tekeminen | Parhaat vinkit huipputarjouksiin'}
      background="/images/blog_category3A.jpg"
      posts={edges} 
      meta={[
      {name:'description', content: 'Minkälainen on hyvä tarjous, ja mitä kaikkea se sisältää? Lue toimivat vinkkimme tarjouksen tekemiseen, ja opi tekemään lyömättömiä tarjouksia!'}
       ]}
      hero={<div>
          <h1 className={"font_second semibold font40"}>Tarjouksen tekeminen – parhaimmat vinkit huipputarjouksiin</h1>
          <p className="font20 padding_top20 opacity9 text">
            Minkälainen on hyvä tarjous ja mitä sen tulisi sisältää? Kuinka tehdä tarjous, josta asiakas ei voi kieltäytyä? Tarjouksen tekeminen voi joskus olla kinkkistä ja aikaavievää työtä. Näillä vinkeillä tehostat tarjoustyötäsi ja opit, miten teet huipputarjouksen!
          </p>
      </div>}>
      <p className="font20 padding_top50 opacity9">
      
      <hr></hr>
      <hr></hr>
      
      <br></br>
      
      <h2>Mihin tarjousta tarvitaan?</h2>
      
      <br></br>
      
      Tarjous on virallinen dokumentti, jossa palveluntarjoaja esittää työn ja sen hinnan potentiaaliselle asiakkaalleen. Oikeudellisesti sopimus syntyy, kun tarjoukseen vastataan hyväksyvästi sen voimassaoloajan sisällä, tarjouksen sisältöjen mukaisesti.
      
      <br></br>
      <br></br>
      
      Tarjous voidaan lähettää yksittäiselle henkilölle tai yritykselle, ja tavallisesti se on vastaus esitettyyn tarjouspyyntöön. Tarjouksessa on paljon samoja elementtejä kuin myyntipuheessa ja työhaastattelussa: työn tarjoaja myy työpanostaan ja perustelee, miksi juuri hän on paras taho toteuttamaan työn.
      
      <br></br>
      <br></br>
      
      Hyvässä tarjouksessa palveluntarjoaja määrittelee tarjotun työn ja kertoo, kuinka se toteutetaan. Lisäksi tarjouksen tulee sisältää työn hinta-arvio tai tarkka hinta.
     
      <br></br>
      <br></br>
      <br></br>
      
      <h2>Tarjouksen tekeminen on helppoa, kun tietää mitä tekee</h2>
      
      <br></br>
      
      Usein uusia yrittäjiä jännittää ensimmäisten tarjousten tekeminen. Mitä tarjoukseen tulee sisällyttää, millainen sen tulee olla, ja mistä aloittaa?
 
      <br></br>
      <br></br>
 
      Neuvoa kannattaa kysyä kokeneemmilta kollegoilta ja opiskella asiaa – onneksi verkosta löytyy paljon tietoa ja <a href="https://www.duuers.com/download/Tarjouspohja.pdf"><font color="#1f77ff">hyviä tarjouspohjia</font></a>, joten aivan tyhjästä ei tarvitse aloittaa. Tarjouksen tekemiseen menee kuitenkin aina oma aikansa, mutta onneksi sitäkin voi nopeuttaa <a href="https://www.duuers.com/fi/tarjoustyokalu"><font color="#1f77ff">helppokäyttöisen tarjoustyökalun</font></a> avulla.
     
      <br></br>
      <br></br>
      <br></br>
      
      <h2>Tarjouksen tekeminen viidessä askeleessa</h2>
      
      <br></br>
      
      Tarjouksen sisällöt vaihtelevat hieman alasta ja työstä riippuen – rakennusalan yrityksen tarjous on toki hieman erilainen kuin digitoimiston tarjous. Tavallisesti tarjousprosessi etenee kuitenkin yleisellä tasolla samankaltaisesti, viittä perusaskelta noudattaen:
     
      <br></br>
      <br></br>
     
      <b>1. Kokoa kaikki tieto, mitä sinulla on</b>
      
      <br></br>
      <br></br>
      
      Mitä kaikkea tiedät projektista, potentiaalisesta asiakkaastasi ja hänen tarpeistaan? Minkälainen on työn tilaajan aikataulu? Tiedätkö, kuka lukee tarjouksen ja tekee päätöksen työn tilaamisesta?  
 
      <br></br>
      <br></br>
 
      Onko sinulla kysymyksiä, joihin tarvitset vastaukset tehdäksesi hyvän tarjouksen? Tutki asiaa, ja selvitä vastaukset kaikkiin kysymyksiisi.

      <br></br>
      <br></br>
     
      <b>2. Aseta projektin tavoite ja reunaehdot</b>
      
      <br></br>
      <br></br>
      
      Määrittele tarkasti, mikä on potentiaalisen asiakkaasi tavoite, ja mitä se sinulta vaatii? Mitä kaikkea työn tavoite sisältää, ja mitä asioita se ei sisällä? Määrittele, kuinka paljon työtä täytyy tehdä, jotta saat työn valmiiksi. Tässä vaiheessa kysymykset ”kuka, mitä, miten, missä, miksi ja milloin” auttavat sinua selkeyttämään kokonaisuutta.
      
      <br></br>
      <br></br>
     
      <b>3. Arvioi työn määrä ja kulut</b>
      
      <br></br>
      <br></br>
      
      Tässä vaiheessa kannattaa viimeistään hyödyntää <a href="https://www.duuers.com/fi/blog/tarjouksen-tekeminen/kustannuslaskenta-5-kriittista-syyta"><font color="#1f77ff">kustannuslaskentaa</font></a> ja arvioida, paljonko työtä ja kuluja projekti vie, ja mikä on kannattava hinta kokonaisuudelle. Myös projektisuunnitelman hahmottaminen auttaa suunnittelussa.
     
      <br></br>
      <br></br>
     
      <b>4. Suunnittele tarjouksesi rakenne ja kirjoita se</b>
      
      <br></br>
      <br></br>
      
      Aloita <a href="https://www.duuers.com/fi/blog/tarjouksen-tekeminen/tarjouksen-kirjoittaminen"><font color="#1f77ff">tarjouksen kirjoittaminen</font></a> sen rakenteen suunnittelemisella. Muista pitää tarjous selkeänä, tiiviinä ja oikeakielisenä.
     
      <br></br>
      <br></br>
     
      <b>5.  Lähetä tarjous ja muista seuranta</b>
      
      <br></br>
      <br></br>
      
      Lähetä tarjouksesi sovitulla tavalla. Kysy potentiaalisen asiakkaasi mielipidettä viimeistään muutama päivä ennen tarjouksen viimeistä voimassaolopäivää, mikäli hän ei ole vielä vastannut. Jos tarjouksen voimassaoloaika on useampia viikkoja, soita tai lähetä sähköposti noin viikko tarjouksen lähettämisen jälkeen.
      
      <br></br>
      <br></br>
      <br></br>
      
      <h2>Viekö tarjousten tekeminen kaiken aikasi?</h2>
      
      <br></br>

      Tarjouksen tekemiseen on tärkeää panostaa, sillä se on kriittinen osa myyntiputkea. Se ei kuitenkaan saa viedä kaikkea aikaasi – tärkeintä on, että pystyt tekemään mahdollisimman paljon tuottavaa työtä ja viettämään myös vapaa-aikaa. <a href="https://www.duuers.com/fi/tarjoustyokalu"><font color="#1f77ff"><b>Duuersin</b> tarjoustyökalu</font></a> nopeuttaa tarjoustyöskentelyäsi huomattavasti ja sen avulla luot tarjoukset vain muutamassa minuutissa. <a href="https://app.duuers.com/auth/signup?lng=fi"><font color="#1f77ff">Testaa ilmaiseksi nyt!</font></a>
      
      </p>
    </Category>
  )
})

export const pageQuery = graphql `
  query {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { in: ["tarjouksen-tekeminen"] }, date: { ne: null } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            path
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
          }
        }
      }
    }
  }
`
